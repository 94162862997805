import React from 'react'

const Logo = () => (
  <svg width="78" height="50" viewBox="0 0 78 50" xmlns="http://www.w3.org/2000/svg">
    <title>Jocelyn McKay Photography logo</title>
    <path
      d="M0 49.5V9H8.5V5.5H19V9H32.2025
        L34.5 0H50.5
        L53 9H77.5V49.5H0Z 
        L13.7003 41.81C15.4338 42.2433 17.745 42.46 20.6341 42.46C24.9918 42.46 28.0976 41.822 29.9514 40.546C31.8293 39.27 32.5756 37.681 32.5756 35.0567V23.0189V17H18.9729V34.6956C18.9729 36.405 18.5877 37.6569 17.8173 38.4514C17.0709 39.2218 16.0718 39.607 14.8199 39.607C12.0271 39.607 10.6307 37.9699 10.6307 34.6956V32.7094H8.5V34.6956C8.5 36.742 8.93336 38.331 9.80008 39.4626C10.6668 40.5701 11.9669 41.3525 13.7003 41.81Z
        
        L43.1208 17H32.5756V23.3199
        L42.0735 42.2794H45.2154
        L52.5825 23.3199V42.2794H64.1388V17H52.5825
        L48.3933 27.5451L43.1208 17Z"
    />
  </svg>
)

export default Logo
